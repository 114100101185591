<template>
  <div class="flexC" style="align-items: center">
    <img alt="Ink Ninja logo" src="../assets/InkNinja.jpg" style="height: 50vh" />
    <!-- <div style="display: block">{{ getWidth() }}</div> -->
    <br />
    <div class="err">&nbsp;{{ msg }}</div>
    <br />
    <div class="center mywidth">
      <b>Логин</b>
      &nbsp;&nbsp;
      <input ref="uname" type="text" v-model="uname" />
    </div>
    <br />
    <br />
    <br />
    <div class="center mywidth">
      <b>Пароль</b>
      &nbsp;&nbsp;
      <input
        autocomplete="off"
        @keydown.enter.prevent="login()"
        type="password"
        v-model="upass"
      />
    </div>
    <br />
    <br />
    <br />
    <div class="center mywidth">
      <button class="btn" @click="login()">Войти</button>
    </div>
  </div>
</template>
<style scoped>
.mywidth {
  width: 15rem;
}
</style>
<script>
import { request } from "@/components-js/requestSrv";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  name: "Login",
  data: function () {
    return {
      uname: null,
      upass: null,
      msg: null,
    };
  },
  mounted() {
    this.$refs.uname.focus();
    loStorage.clear();
  },
  methods: {
    login: async function () {
      const res = await request("api/auth/login", "POST", {
        uname: this.uname,
        upass: this.upass,
      });

      if (res != "Login error") {
        loStorage.set(res.token);
        this.$router.push({ name: "Today" });
      } else {
        this.msg = "неверный логин или пароль";
        setTimeout(() => {
          this.msg = null;
        }, 2000);
      }
    },
    getWidth() {
      return window.innerWidth;
    },
  },
};
</script>
